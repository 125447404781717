<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <!-- <sidebar-fixed-toggle-button /> -->
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot-scope="props" slot="links">
        <user-menu></user-menu>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'lni lni-dashboard',
            path: '/dashboard',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="type != 4 && type != 3"
          :link="{
            name: 'Startups',
            icon: 'lni lni-bulb',
            path: '/startups',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'Calender',
            icon: 'fas fa-calendar-week',
            path: '/calender'
          }"
        ></sidebar-item> -->
        <sidebar-item
          v-if="type != 3"
          :link="{
            name: 'Partners',
            icon: 'lni lni-users',
            path: '/partners',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="type == 1 || type == 3"
          :link="{
            name: 'Programs',
            icon: 'lni lni-bricks',
            path: '/programs',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="type == 1"
          :link="{
            name: 'Invite',
            icon: 'fas fa-share-alt',
            path: '/invite',
          }"
        ></sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'Actions',
            icon: 'fas fa-flask',
            path: '/actions'
          }"
        ></sidebar-item> -->
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar class="dash-nav"></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// import SidebarShare from './SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import UserMenu from "./Extra/UserMenu.vue";

import DashboardNavbar from "./DashboardNavbar.vue";
import DashboardContent from "./Content.vue";
import db from "@/components/Firebase/firebaseInit";

import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    UserMenu,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    // SidebarShare
  },
  data() {
    return {
      sidebarBackground: "red", //vue|blue|orange|green|red|primary
      programID: null,
    };
  },
  computed: {
    type() {
      let x = this.$store.state.models.type;
      let y;
      if (x == "admin") {
        y = 1;
      } else if (x == "program") {
        y = 2;
      } else if (x == "partner") {
        y = 3;
      } else if (x == "startup") {
        y = 4;
      }
      return y;
    },
  },
  created() {
    db.collection("programs")
      .where("email", "==", this.$store.state.models.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.programID = doc.id;
        });
      });
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
.content {
  overflow: hidden;
}
.sidebar-wrapper {
  display: grid;
  padding: 2em 0em;
  grid-template-rows: 1fr 10fr;
  padding-left: 2em;

  div.logo {
    padding: 0px !important;
    display: flex;
    justify-content: flex-start;
    img {
      border-radius: 0px;
      height: 1.8em;
      &::after {
        display: none;
      }
    }
  }
  ul.nav {
    div.user {
      div.photo {
        margin-left: 0px;
      }
      div.info {
        a {
          b.caret {
            top: 12px;
            right: 0px;
          }
          span {
            font-weight: 600;
          }
        }
      }
    }
    li {
      a {
        margin-left: 0px;
        padding-left: 0px;
        i {
          background-color: rgba(9, 25, 40, 0.07);
          border-radius: 0.625rem;
        }
      }
      a.router-link-exact-active.active {
        i {
          background-color: rgb(242, 71, 66);
          color: white !important;
        }
        p {
          font-weight: 600;
        }
      }
    }
  }
}

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
.hr-white {
  border: 0.5px dotted #fff;
}

.white-content .sidebar p {
  color: #253341;
}

.sidebar .nav li > a:not([data-toggle="collapse"]) i,
.sidebar .nav li > a:not([data-toggle="collapse"]) p,
.off-canvas-sidebar .nav li > a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar .nav li > a:not([data-toggle="collapse"]) p {
  color: #253341 !important;
}

.sidebar .nav li.active > a:not([data-toggle="collapse"]) i,
.sidebar .nav li.active > a:not([data-toggle="collapse"]) p,
.off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]) p {
  color: #f24742 !important;
}

.sidebar .nav li.active > a:not([data-toggle="collapse"]) i,
.sidebar .nav li.active > a:not([data-toggle="collapse"]) p,
.off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]) p {
  color: #000;
}
.sidebar .nav li:hover:not(.active) > a p,
.sidebar .nav li:hover:not(.active) > a i,
.sidebar .nav li:focus:not(.active) > a p,
.sidebar .nav li:focus:not(.active) > a i,
.off-canvas-sidebar .nav li:hover:not(.active) > a p,
.off-canvas-sidebar .nav li:hover:not(.active) > a i,
.off-canvas-sidebar .nav li:focus:not(.active) > a p,
.off-canvas-sidebar .nav li:focus:not(.active) > a i {
  color: #253341;
}

.sidebar,
.off-canvas-sidebar {
  height: calc(100vh) !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  border-radius: 0px;
}
.main-panel[data="red"] {
  border-top: 0px solid #ec250d !important;
}

@media (min-width: 1200px) {
  .dash-nav {
    display: none;
  }
  .main-panel > .content {
    padding: 30px 30px 30px 280px !important;
    min-height: calc(100vh - 35px);
  }
}
</style>

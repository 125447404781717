// store/index.js

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import db from "@/components/Firebase/firebaseInit";
import firebase from "firebase";
// import { collection, query, where, getDocs } from "firebase/firestore";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    models: {
      globalError: "",
      userData: null,
      guest: false,
      type: null,
      email: null,
      id: null,
      loggedIn: false,
    },
  },
  mutations: {
    setAuthentication(state, status) {
      state.models.user.loggedIn = status;
    },

    login(state, payload) {
      state.models.loggedIn = true;
      state.models.type = payload.type;
      state.models.email = payload.email;
      state.models.id = payload.id;
    },
    logout(state) {
      state.models.loggedIn = false;
      state.models.email = null;
      state.models.globalError = "";
      state.models.type = null;
      state.models.id = null;
    },
  },
  getters: {},
  actions: {
    userLogin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        db.collection("userbase")
          .where("email", "==", payload.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              reject({ message: "This Email is not found" });
            }

            querySnapshot.forEach((doc) => {
              console.log(doc.data());
              // this.$store.state.models.id = doc.id;
              // console.log('this is ' + doc.id);
              firebase
                .auth()
                .signInWithEmailAndPassword(payload.email, payload.password)
                .then(
                  () => {
                    commit("login", {
                      type: doc.data().type,
                      email: payload.email,
                      id: doc.id,
                    });
                    resolve();
                  },
                  (err) => {
                    reject(err);
                  }
                );
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    userLogOut({ commit }) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            commit("logout");
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
  },
});

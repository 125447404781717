import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
// import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/login.vue');
  const startupInvite = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/startupInvite.vue');
const ForgotPassword = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Ice/forgotPassword.vue');


const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');


  //Ice I80 Pages
  const Calender = () => import('src/pages/Ice/calender.vue');

  //Settings
  const Settings = () => import('src/pages/Ice/settings.vue')


  //Partners
  const Partners = () => import('src/pages/Ice/partners.vue')
  const PartnersAdd = () => import('src/pages/Ice/partnersAdd.vue')
  const PartnersDetail = () => import('src/pages/Ice/partnersDetail.vue')

  //Invite 
  const Invite = () => import('src/pages/Ice/Invite.vue')


  //Program
  const Programs = () => import('src/pages/Ice/programs.vue')
  const ProgramsAdd = () => import('src/pages/Ice/programAdd.vue')
  const ProgramsDetail = () => import('src/pages/Ice/programDetail.vue')

  const milestoneAdd = () => import('src/pages/Ice/milestoneAdd.vue')

  //Startup
  const Startups = () => import('src/pages/Ice/startups.vue')
  const StartupAdd = () => import('src/pages/Ice/startupAdd.vue')
  const StartupDetail = () => import('src/pages/Ice/startupDetail.vue')
  const StartupMilestoneAdd = () => import('src/pages/Ice/startupMilestoneAdd.vue')
  const StartupProgressEdit = () => import('src/pages/Ice/progressEdit.vue')

  const StartupLogo = () => import('src/pages/Ice/startupAddLogo.vue')

  const MeamberAdd = () => import('src/pages/Ice/meamberAdd.vue')
  const ProductAdd = () => import('src/pages/Ice/productAdd.vue')  
  const ActionAdd = () => import('src/pages/Ice/actionAdd.vue')

  const Actions = () => import('src/pages/Ice/actions.vue')

  //Edit Pages
  const actionEdit = () => import('src/pages/IceEdit/actionEdit.vue')
  const meamberEdit = () => import('src/pages/IceEdit/meamberEdit.vue')
  const milestoneEdit = () => import('src/pages/IceEdit/milestoneEdit.vue')
  const partnerEdit = () => import('src/pages/IceEdit/partnerEdit.vue')
  const productEdit = () => import('src/pages/IceEdit/productEdit.vue')
  const programEdit = () => import('src/pages/IceEdit/programEdit.vue')
  const startupEdit = () => import('src/pages/IceEdit/startupEdit.vue')
  const startupMilestoneEdit = () => import('src/pages/IceEdit/startupMilestoneEdit.vue')


let icePages = {
  path: '/',
  component: DashboardLayout,
  name: 'admin',
  redirect: '/',
  meta: {
    requiresAuth: false
  },
  children: [
    {
      path: 'partners',
      name: 'Partners',
      components: { default: Partners }
    },
    {
      path: 'partners/add',
      name: 'Partners-Add',
      components: { default: PartnersAdd }
    },
    {
      path: 'partners/detail/:partner_id',
      name: 'Partners-Detail',
      components: { default: PartnersDetail }
    },
    {
      path: 'partners/edit/:partner_id',
      name: 'Partners-Edit',
      components: { default: partnerEdit }
    },
    {
      path: 'startups',
      name: 'Startups',
      components: { default: Startups }
    },
    {
      path: 'settings',
      name: 'Settings',
      components: { default: Settings }
    },
    {
      path: 'startup/add',
      name: 'Startup-Add',
      components: { default: StartupAdd }
    },
    {
      path: 'startup/edit/:startup_id',
      name: 'Startup-Edit',
      components: { default: startupEdit }
    },
    {
      path: 'startup/actionAdd',
      name: 'Action-Add',
      components: { default: ActionAdd }
    },
    {
      path: 'startup/logo/:startupID',
      name: 'Startup-Logo',
      components: { default: StartupLogo }
    },
    {
      path: 'startup/milestone',
      name: 'Startup-Milestone',
      components: { default: StartupMilestoneAdd }
    },
    {
      path: 'startupMilestone/edit/:startup_id',
      name: 'Startup-Milestone-Edit',
      components: { default: startupMilestoneEdit }
    },
    {
      path: 'startup/progress',
      name: 'Startup-Progress-Edit',
      components: { default: StartupProgressEdit }
    },
    {
      path: 'startup/meamberAdd',
      name: 'Meamber-Add',
      components: { default: MeamberAdd }
    },
    {
      path: 'startupMeamber/edit/:program_id',
      name: 'Meamber-Edit',
      components: { default: meamberEdit }
    },
    {
      path: 'startup/productAdd',
      name: 'Product-Add',
      components: { default: ProductAdd }
    },
    {
      path: 'startupProduct/edit/:product_id',
      name: 'Product-Edit',
      components: { default: productEdit }
    },
    {
      path: 'startup/detail/:startup_id',
      name: 'Startup-Detail',
      components: { default: StartupDetail }
    },
    {
      path: 'programs',
      name: 'Programs',
      components: { default: Programs }
    },
    {
      path: 'invite',
      name: 'Invite',
      components: { default: Invite }
    },
    {
      path: 'programs/add',
      name: 'Programs-Add',
      components: { default: ProgramsAdd }
    },
    {
      path: 'programs/edit/:program_id',
      name: 'Program-Edit',
      components: { default: programEdit }
    },
    {
      path: 'programs/milestoneAdd',
      name: 'Milestone-Add',
      components: { default: milestoneAdd }
    },
    {
      path: 'programMilestone/edit/:milestone_id',
      name: 'Program-Milestone-Edit',
      components: { default: milestoneEdit }
    },
    {
      path: 'programs/detail/:program_id',
      name: 'Programs-Detail',
      components: { default: ProgramsDetail }
    },
    {
      path: 'calender',
      name: 'Calender',
      components: { default: Calender }
    },
    {
      path: 'actions',
      name: 'Actions',
      components: { default: Actions }
    },
    {
      path: 'actions/edit/:program_id',
      name: 'Action-Edit',
      components: { default: actionEdit }
    },
  ]
};


let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/startupInvite',
      name: 'startupInvite',
      component: startupInvite
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home'
  },
  icePages,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      }
    ]
  },
  // { path: '*', component: NotFound }
];

export default routes;

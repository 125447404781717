var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('user-menu'),_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'lni lni-dashboard',
          path: '/dashboard',
        }}}),(_vm.type != 4 && _vm.type != 3)?_c('sidebar-item',{attrs:{"link":{
          name: 'Startups',
          icon: 'lni lni-bulb',
          path: '/startups',
        }}}):_vm._e(),(_vm.type != 3)?_c('sidebar-item',{attrs:{"link":{
          name: 'Partners',
          icon: 'lni lni-users',
          path: '/partners',
        }}}):_vm._e(),(_vm.type == 1 || _vm.type == 3)?_c('sidebar-item',{attrs:{"link":{
          name: 'Programs',
          icon: 'lni lni-bricks',
          path: '/programs',
        }}}):_vm._e(),(_vm.type == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Invite',
          icon: 'fas fa-share-alt',
          path: '/invite',
        }}}):_vm._e()]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar',{staticClass:"dash-nav"}),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="user user__menu">
    <div class="photo">
      <img :src="image" alt="avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        href="#"
      >
        <span>
          {{ this.title }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav user-menu__nav" v-show="!isClosed">
            <slot>
              <li>
                <a href="/#/settings">
                  <span class="sidebar-mini-icon">S</span>
                  <span class="sidebar-normal">Settings</span>
                </a>
              </li>
              <li>
                <a href="#" v-on:click="logout">
                  <span class="sidebar-mini-icon">L</span>
                  <span class="sidebar-normal">Logout</span>
                </a>
              </li>
            </slot>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { mapActions } from "vuex";

export default {
  name: "user-menu",
  components: {
    CollapseTransition,
  },
  // props: {
  //   title: {
  //     type: String,
  //     default: "Markos Lemma"
  //   },
  //   image: {
  //     type: String,
  //     default: "img/mark.jpg"
  //   }
  // },
  data() {
    return {
      isClosed: true,
    };
  },
  computed: {
    type() {
      let x = this.$store.state.models.type;
      let y;
      if (x == "admin") {
        y = 1;
      } else if (x == "program") {
        y = 2;
      } else if (x == "partner") {
        y = 3;
      } else if (x == "startup") {
        y = 4;
      }
      return y;
    },
    image() {
      let x = this.$store.state.models.type;
      let y;
      if (x == "admin") {
        y = "img/mark.jpg";
      } else if (x == "program") {
        y = "img/placeholder.jpg";
      } else if (x == "partner") {
        y = "img/placeholder.jpg";
      } else if (x == "startup") {
        y = "img/placeholder.jpg";
      }
      return y;
    },
    title() {
      let x = this.$store.state.models.type;
      let y;
      if (x == "admin") {
        y = "Markos Lemma";
      } else if (x == "program") {
        y = this.$store.state.models.email;
      } else if (x == "partner") {
        y = this.$store.state.models.email;
      } else if (x == "startup") {
        y = this.$store.state.models.email;
      }
      return y;
    },
  },
  methods: {
    ...mapActions(["userLogOut"]),
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    async logout() {
      await this.userLogOut().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
.sidebar .user a,
.off-canvas-sidebar .user a {
  color: #000 !important;
}
.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal {
  color: #253341 !important;
}
.sidebar .user:after,
.off-canvas-sidebar .user:after {
  background: #f5f6f6 !important;
}
.sidebar .user .photo,
.off-canvas-sidebar .user .photo {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 30%) !important;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 30%) !important;
}
.sidebar .nav li:first-child > a,
.off-canvas-sidebar .nav li:first-child > a {
  margin: 0px !important;
}
</style>
